<template>
  <div id="app">
    <div class="weather-container" v-if="weatherData.length > 0">
      <div v-for="(day, index) in weatherData" :key="index" class="day-weather">
        <h3 class="date">{{ formatDate(day.date) }}</h3>
        <img :src="day.day.condition.icon" alt="날씨 아이콘" class="weather-icon">
        <p class="condition">{{ day.day.condition.text }}</p>
        <p class="temperature">최저: {{ day.day.mintemp_c }}°C / 최고: {{ day.day.maxtemp_c }}°C</p>
      </div>
    </div>
    <div v-else>
      <p>날씨 정보를 불러오는 중입니다...</p>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'App',
  data() {
    return {
      weatherData: [],
    };
  },
  methods: {
    async fetchWeatherData() {
      const apiKey = '945188d01375420ba2575031243110'; // API 키를 여기에 입력
      const city = 'Seoul'; // 원하는 도시 설정
      const url = `https://api.weatherapi.com/v1/forecast.json?key=${apiKey}&q=${city}&days=7&aqi=no&alerts=no&lang=ko`;

      try {
        const response = await axios.get(url);
        this.weatherData = response.data.forecast.forecastday;
      } catch (error) {
        console.error("날씨 데이터를 가져오는 중 오류가 발생했습니다:", error);
      }
    },
    formatDate(dateString) {
      const date = new Date(dateString);
      return date.toLocaleDateString("ko-KR", {
        weekday: "long",
        month: "long",
        day: "numeric",
      });
    },
  },
  mounted() {
    this.fetchWeatherData();
  },
};
</script>

<style>
#app {
  font-family: Arial, sans-serif;
  text-align: center;
  margin-top: 0px;
}
.weather-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 5px; /* 글 사이 패딩 줄이기 */
}
.day-weather {
  flex-grow: 1;
  text-align: center;
  font-size: 0.9em; /* 기본 글씨 크기 */
  line-height: 1.2em; /* 행간을 더 줄이기 */
}
.date {
  font-size: 0.9em; /* 날짜 글씨 크기 더 작게 */
}
.weather-icon {
  width: 120px; /* 아이콘 크기 줄이기 */
  height: 120px;
}
.condition, .temperature {
  margin: 0; /* 각 텍스트 요소 사이 패딩 제거 */
}
</style>
