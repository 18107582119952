<template >
  <div class="cntsTitle2">
    뉴스
  </div>
  <div class="news" >
    <template v-if="load==true">
      <template v-for="(item, index) in dataList" v-bind:key="index">
        <table class="newsTable">
          <template v-if="typeof item  !== 'undefined'">
            <template v-for='j in parseInt(item.loofCnt)' v-bind:key="j">
            <tr>
              <td class="newsType" v-if="j==1" :rowspan=item.loofCnt>
                {{item.name}}
              </td>
              <td class="newsTitle">
                  &bull; {{ item.datas[j-1].title }}
                  <ld class="writeTime"> {{ (new Date(item.datas[j-1].pubDate)).toLocaleTimeString()    }}</ld>
              </td>
            </tr>
            </template>
          </template>

        </table>
      </template>
    </template>


  </div>
</template>

<script>
import axios from "axios";
import xmlParser from "fast-xml-parser";

export default {
  name: 'TimeCompo',
  components: {},
  props: {},
  data() {
    return {
      load: false,
      dataList: [],
    }
  },
  methods:{


  },
  created() {
    var news = [
        {url:"https://www.yonhapnewstv.co.kr/category/news/headline/feed/", loofCnt:5, name:"연합뉴스"}
      // ,{url:"https://fs.jtbc.co.kr//RSS/newsflash.xml", loofCnt:5, name:"JTBC"}
      ,{url:"http://rss.etnews.com/04.xml", loofCnt:5, name:"전자신문"}
      // ,{url:"http://www.ilovepc.co.kr/rss/allArticle.xml", loofCnt:5, name:"PC사랑"}
      // ,{url:"https://www.khan.co.kr/rss/rssdata/sksports_news.xml", loofCnt:4, name:"스포츠"}
      ,{url:"http://www.ksdaily.co.kr/rss/allArticle.xml", loofCnt:3, name:"증권"}
    ];


// axios 요청에 catch 추가, 로딩 및 에러 처리 개선
    news.forEach((item, i) => {
      axios
          .get(this.$parent.serverURL + this.$parent.getURLEncode(item.url), {
            headers: {
              "Content-Type": "application/xml",
              "Access-Control-Allow-Origin": "*",
              "Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept"
            }
          })
          .then(response => {
            const parser = new xmlParser.XMLParser();
            const xml = response.data.replace(/<br\s*\/?>/gi, "").replace("dc:date", "pubDate");
            const data = parser.parse(xml);
            this.dataList[i] = {
              name: item.name,
              datas: data.rss.channel.item,
              loofCnt: item.loofCnt
            };
            this.load = true;
          })
          .catch(error => {
            console.error("뉴스 데이터를 가져오는 중 오류가 발생했습니다:", error);
          });
    });


    this.load = true



  }



}
</script>
<style>
.cntsTitle2 {
  font-size: 24px; /* 제목 글씨 크기 증가 */
  margin-top: 20px;
  margin-left: 10px;
  text-align: left;
  font-weight: bold;
  color: #333; /* 짙은 색상으로 가독성 증가 */
}

.newsTable {
  width: 100%;
  margin-top: 20px;
  border-collapse: separate;
  border-spacing: 0 15px; /* 행 사이 간격을 추가하여 카드 형태로 분리 */
}

.newsTable .newsType {
  width: 20%;
  font-size: 18px; /* 뉴스 출처 글씨 크기 증가 */
  text-align: center;
  font-weight: bold;
  color: #0056b3; /* 색상 추가 */
  padding: 10px 5px;
  border-radius: 5px 0 0 5px; /* 둥근 모서리 */
}

.newsTable .newsTitle {
  font-size: 20px; /* 뉴스 제목 글씨 크기 증가 */
  text-align: left;
  padding: 10px;
  color: #333; /* 기본 텍스트 색상 */
  line-height: 1.6; /* 행간 조정으로 가독성 증가 */
  border-radius: 0 5px 5px 0; /* 둥근 모서리 */
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1); /* 그림자를 추가하여 카드 형태 */
  background-color: #fff; /* 카드 배경을 흰색으로 설정 */
}

.newsTable tr {
  background-color: #fff; /* 카드 배경을 흰색으로 설정 */
  border-radius: 5px; /* 카드 모서리 둥글게 */
}

.newsTable .newsTitle .writeTime {
  font-size: 14px; /* 작성 시간 글씨 크기 증가 */
  color: #767676; /* 회색으로 시각적 구분 */
  margin-left: 15px;
}

</style>
