<template>
  <div class="footer">
    <marquee scrollamount="5">
      <div v-html="headline"></div>
    </marquee>
  </div>
</template>

<script>
import axios from "axios";

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'Footer',
  components: {
  },
  props: {
    msg: String
  },
  data(){
    return{
      arrStockList:[
        {stockCode: "020560", title: "아시아나"},
        {stockCode: "115310", title: "인포바인"},
        {stockCode: "035720", title: "카카오"},
        {stockCode: "035420", title: "네이버"},
      ],
      headline:"",
    }
  },
  methods:{
     getRandomInt:function(max) {
      return Math.floor(Math.random() * max);
      }
  },
  created() {


    axios.get(this.$parent.serverStockTotURL, {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept"
      },
    })
    .then(response => {
      console.log(response.data)
      if(response.data.blind == "하락" ){
        this.headline =  "<font color='blue'>" + "KOSPI: " + response.data.kospi + "(" + response.data.range  + ")" + "&nbsp&nbsp&nbsp&nbsp&nbsp</font>"
      }else{
        this.headline =  "<font color='red'>" + "KOSPI: " + response.data.kospi + "(" + response.data.range  + ")" + "&nbsp&nbsp&nbsp&nbsp&nbsp</font>"
      }
      const now = new Date();
      const start = new Date();
      const end = new Date();
      start.setHours(9, 0, 0, 0); // 시작 시간 9시로 설정
      end.setHours(18, 0, 0, 0); // 종료 시간 17시로 설정

      if(now.getDay() == 0 || now.getDay() == 6){
        this.headline += "일과 외 시간 입니다."
        return true;
      }

      if (now.getTime() >= start.getTime() && now.getTime() < end.getTime()) {
        this.headline +=""
      } else {
        this.headline += "일과 외 시간 입니다."
        return true;
      }



      // console.log(response.data.kospi)
      this.arrStockList.forEach((item) => {

        axios.get(this.$parent.serverStockURL + item.stockCode, {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept"
          },
        })
            .then(response => {
              // console.log(response.data)
              var stockData = response.data.msg

              if(stockData.indexOf("하락") > -1 || stockData.indexOf("하한") > -1){
                this.headline += "<font color='blue'>" + item.title + ": " + stockData + "&nbsp&nbsp&nbsp&nbsp&nbsp</font>"
              }
              else if(stockData.indexOf("상승") > -1 || stockData.indexOf("상한") > -1){
                this.headline += "<font color='red'>" + item.title + ": " + stockData + "&nbsp&nbsp&nbsp&nbsp&nbsp</font>"
              }else{
                this.headline += item.title + ": " + stockData +"&nbsp&nbsp&nbsp&nbsp&nbsp"
              }

            });

      })


    });







    this.loaded = true;





  }


}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.footer{
  height: 20px;
  font-size: 14px;
}

.blueFont{
  color: blue
}

.redFont{
  color: red
}
</style>
