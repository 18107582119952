<template>
  <div class="calendar-compo">
    <div class="calendar-container">
      <div class="calendar-body">
        <h3 class="calendar-month" @click="goToPreviousMonth">{{ previousYear }}년 {{ previousMonth + 1 }}월</h3>
        <div class="calendar-week">
          <div v-for="(day, index) in daysOfWeek" :key="day" class="day-name" :class="{ 'sunday': index === 0, 'saturday': index === 6 }">
            {{ day }}
          </div>
        </div>
        <div class="calendar-days">
          <div v-for="empty in emptyDays(previousYear, previousMonth)" :key="empty" class="day empty"></div>
          <div v-for="day in daysInMonth(previousYear, previousMonth)" :key="day" class="day" :class="getDayClass(previousYear, previousMonth, day)">
            {{ day }}
          </div>
        </div>
      </div>
      <div class="calendar-body">
        <h3 class="calendar-month">{{ currentYear }}년 {{ currentMonth + 1 }}월</h3>
        <div class="calendar-week">
          <div v-for="(day, index) in daysOfWeek" :key="day" class="day-name" :class="{ 'sunday': index === 0, 'saturday': index === 6 }">
            {{ day }}
          </div>
        </div>
        <div class="calendar-days">
          <div v-for="empty in emptyDays(currentYear, currentMonth)" :key="empty" class="day empty"></div>
          <div v-for="day in daysInMonth(currentYear, currentMonth)" :key="day" class="day" :class="getDayClass(currentYear, currentMonth, day)">
            {{ day }}
          </div>
        </div>
      </div>
      <div class="calendar-body">
        <h3 class="calendar-month" @click="goToNextMonth">{{ nextYear }}년 {{ nextMonth + 1 }}월</h3>
        <div class="calendar-week">
          <div v-for="(day, index) in daysOfWeek" :key="day" class="day-name" :class="{ 'sunday': index === 0, 'saturday': index === 6 }">
            {{ day }}
          </div>
        </div>
        <div class="calendar-days">
          <div v-for="empty in emptyDays(nextYear, nextMonth)" :key="empty" class="day empty"></div>
          <div v-for="day in daysInMonth(nextYear, nextMonth)" :key="day" class="day" :class="getDayClass(nextYear, nextMonth, day)">
            {{ day }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CalendarCompo",
  data() {
    return {
      today: new Date(),
      currentYear: new Date().getFullYear(),
      currentMonth: new Date().getMonth(),
      daysOfWeek: ["일", "월", "화", "수", "목", "금", "토"],
      holidays: [
        { month: 0, day: 1 },
        { month: 2, day: 1 },
        { month: 4, day: 5 },
        { month: 7, day: 15 },
        { month: 9, day: 3 },
        { month: 9, day: 9 },
        { month: 11, day: 25 },
      ],
    };
  },
  computed: {
    previousYear() {
      return this.currentMonth === 0 ? this.currentYear - 1 : this.currentYear;
    },
    previousMonth() {
      return this.currentMonth === 0 ? 11 : this.currentMonth - 1;
    },
    nextYear() {
      return this.currentMonth === 11 ? this.currentYear + 1 : this.currentYear;
    },
    nextMonth() {
      return (this.currentMonth + 1) % 12;
    },
  },
  methods: {
    daysInMonth(year, month) {
      const date = new Date(year, month + 1, 0);
      return Array.from({ length: date.getDate() }, (_, i) => i + 1);
    },
    emptyDays(year, month) {
      const firstDay = new Date(year, month, 1).getDay();
      return Array.from({ length: firstDay });
    },
    isHoliday(year, month, day) {
      return this.holidays.some((holiday) => holiday.month === month && holiday.day === day);
    },
    isToday(year, month, day) {
      const today = new Date();
      return (
          year === today.getFullYear() &&
          month === today.getMonth() &&
          day === today.getDate()
      );
    },
    isThisWeek(year, month, day) {
      const today = new Date();
      const startOfWeek = new Date(today);
      startOfWeek.setDate(today.getDate() - today.getDay()); // 지난 일요일 포함
      const endOfWeek = new Date(today);
      endOfWeek.setDate(today.getDate() + (6 - today.getDay())); // 이번 주 토요일까지 포함

      const date = new Date(year, month, day);
      return date >= startOfWeek && date <= endOfWeek;
    },
    getDayClass(year, month, day) {
      const date = new Date(year, month, day);
      const dayOfWeek = date.getDay();
      return {
        sunday: dayOfWeek === 0,
        saturday: dayOfWeek === 6,
        holiday: this.isHoliday(year, month, day),
        today: this.isToday(year, month, day),
        thisWeek: this.isThisWeek(year, month, day),
      };
    },
    goToPreviousMonth() {
      if (this.currentMonth === 0) {
        this.currentYear -= 1;
        this.currentMonth = 11;
      } else {
        this.currentMonth -= 1;
      }
    },
    goToNextMonth() {
      if (this.currentMonth === 11) {
        this.currentYear += 1;
        this.currentMonth = 0;
      } else {
        this.currentMonth += 1;
      }
    },
  },
};
</script>

<style scoped>
.calendar-compo {
  width: 900px;
  margin: auto;
  padding: 10px;
  text-align: center;
  font-family: Arial, sans-serif;
  background-color: transparent;
}
.calendar-container {
  display: flex;
  justify-content: space-between;
  gap: 15px;
}
.calendar-body {
  width: 280px;
  padding: 10px;
  background-color: transparent;
}
.calendar-month {
  font-size: 1.4em;
  margin-bottom: 10px;
  color: #4a90e2;
  cursor: pointer;
}
.calendar-week,
.calendar-days {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: 5px;
}
.day-name {
  font-weight: bold;
  padding: 8px;
  color: #333;
}
.day,
.empty {
  padding: 10px;
  height: 60px;
  font-size: 1em;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
}
.empty {
  background-color: transparent;
}
.sunday,
.holiday {
  color: red;
  font-weight: bold;
}
.saturday {
  color: blue;
}
.today {
  border: 2px solid #4a90e2;
  border-radius: 50%;
}
.thisWeek {
  background-color: #f0f0f0; /* 연한 회색 배경 */
  border-radius: 8px;
}
.day {
  transition: background-color 0.3s;
}
.day:hover {
  background-color: #e6f7ff;
  cursor: pointer;
}
</style>
