<template>
  <div class="footer">
    <marquee scrollamount="5">

      <div v-html="headline"></div>
    </marquee>
  </div>
</template>

<script>

import axios from "axios";

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'Footer',
  components: {
  },
  props: {
    msg: String
  },
  data(){
    return{
      arrStockList:[
        {stockCode: "115310", title: "인포바인"},
        {stockCode: "035720", title: "카카오"},
        {stockCode: "035420", title: "네이버"},
      ],
      headline:"",
      exchangeRates: {
        USD: null,
        JPY: null,
        CNY: null,
        SGD: null,
      },
      kospiIndex: null,
      kosdaqIndex: null,
      nasdaqIndex: null,
      loading: true,
    }
  },
  methods:{
     getRandomInt:function(max) {
       return Math.floor(Math.random() * max);
     },
    async fetchExchangeRates() {
      try {
        const response = await fetch("https://api.exchangerate-api.com/v4/latest/KRW");
        const data = await response.json();
        this.exchangeRates.USD = (1 / data.rates.USD).toFixed(2);
        this.exchangeRates.JPY = (1 / data.rates.JPY).toFixed(2);
        this.exchangeRates.CNY = (1 / data.rates.CNY).toFixed(2);
        this.exchangeRates.SGD = (1 / data.rates.SGD).toFixed(2);
      } catch (error) {
        console.error("환율 데이터를 불러오는 중 오류 발생:", error);
      }
    },
    async fetchStockIndices() {
      try {
        // Yahoo Finance에서 KOSPI 지수 가져오기
        const kospiResponse = await fetch(
            `/yahoo/v8/finance/chart/^KS11?interval=1d`
        );
        const kospiData = await kospiResponse.json();
        this.kospiIndex = kospiData.chart.result[0].meta.regularMarketPrice;

        // Yahoo Finance에서 KOSDAQ 지수 가져오기
        const kosdaqResponse = await fetch(
            `/yahoo/v8/finance/chart/^KQ11?interval=1d`
        );
        const kosdaqData = await kosdaqResponse.json();
        this.kosdaqIndex = kosdaqData.chart.result[0].meta.regularMarketPrice;

        // Yahoo Finance에서 나스닥 지수 가져오기
        const nasdaqResponse = await fetch(
            `/yahoo/v8/finance/chart/^IXIC?interval=1d`
        );
        const nasdaqData = await nasdaqResponse.json();
        this.nasdaqIndex = nasdaqData.chart.result[0].meta.regularMarketPrice;
      } catch (error) {
        console.error("주식 지수 데이터를 불러오는 중 오류 발생:", error);
      }
    },
    async fetchFinancialData() {
      this.loading = true;
      await this.fetchExchangeRates();
      await this.fetchStockIndices();
      this.loading = false;
    },
  },
  created() {
    this.fetchFinancialData();



    axios.get(this.$parent.serverStockTotURL, {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept"
      },
    })
        .then(response => {

          console.log(response.data)
          if(response.data.blind == "하락" ){
            this.headline =  "<font color='blue'>" + "KOSPI: " + response.data.kospi + "(" + response.data.range  + ")" + "&nbsp&nbsp&nbsp&nbsp&nbsp</font>"
          }else{
            this.headline =  "<font color='red'>" + "KOSPI: " + response.data.kospi + "(" + response.data.range  + ")" + "&nbsp&nbsp&nbsp&nbsp&nbsp</font>"
          }
          // this.headline +=  "<font color='black'>" + "달러: " + this.exchangeRates.USD + "원&nbsp&nbsp&nbsp&nbsp&nbsp</font>"
          // this.headline +=  "<font color='black'>" + "엔화: " + this.exchangeRates.JPY + "원&nbsp&nbsp&nbsp&nbsp&nbsp</font>"
          // this.headline +=  "<font color='black'>" + "위안화: " + this.exchangeRates.CNY + "원&nbsp&nbsp&nbsp&nbsp&nbsp</font>"
          // this.headline +=  "<font color='black'>" + "싱가포르달러: " + this.exchangeRates.SGD + "원&nbsp&nbsp&nbsp&nbsp&nbsp</font>"

          const now = new Date();
          const start = new Date();
          const end = new Date();
          start.setHours(9, 0, 0, 0); // 시작 시간 9시로 설정
          end.setHours(18, 0, 0, 0); // 종료 시간 17시로 설정

          if(now.getDay() == 0 || now.getDay() == 6){
            this.headline += "일과 외 시간 입니다."
            return true;
          }

          if (now.getTime() >= start.getTime() && now.getTime() < end.getTime()) {
            this.headline +=""
          } else {
            this.headline += "일과 외 시간 입니다."
            return true;
          }



          // console.log(response.data.kospi)
          this.arrStockList.forEach((item) => {

            axios.get(this.$parent.serverStockURL + item.stockCode, {
              headers: {
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept"
              },
            })
                .then(response => {
                  // console.log(response.data)
                  var stockData = response.data.msg

                  if(stockData.indexOf("하락") > -1 || stockData.indexOf("하한") > -1){
                    this.headline += "<font color='blue'>" + item.title + ": " + stockData + "&nbsp&nbsp&nbsp&nbsp&nbsp</font>"
                  }
                  else if(stockData.indexOf("상승") > -1 || stockData.indexOf("상한") > -1){
                    this.headline += "<font color='red'>" + item.title + ": " + stockData + "&nbsp&nbsp&nbsp&nbsp&nbsp</font>"
                  }else{
                    this.headline += item.title + ": " + stockData +"&nbsp&nbsp&nbsp&nbsp&nbsp"
                  }

                });

          })





        });
    // 달러: {{ exchangeRates.USD }}원 엔화: {{ exchangeRates.JPY }}원  위안화: {{exchangeRates.CNY}}원 싱가포르달러: {{exchangeRates.SGD}}원
    // 코스피: {{kospiIndex}} 코스닥: {{kosdaqIndex}} 나스닥:{{nasdaqIndex}}








  }

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.footer{
  height: 20px;
  font-size: 14px;
}

.blueFont{
  color: blue
}

.redFont{
  color: red
}
</style>
