<template>
  <div class="gnb">
      <div class="date"> {{nowDate}} ({{nowDayOfWeak}}) </div>

    <span class="time2">
      <span class="time1">{{ nowTimeFirst }} </span>{{nowTime}}
      </span>
  </div>
</template>

<script>
export default {
  name: 'TimeCompo',
  components: {
  },
  data(){
    return{
      nowDate: "",
      nowDayOfWeak: "",
      nowTime: "",
      nowTimeFirst: "",
      interval: "",

    }
  },
  props: {
  },
  methods:{
    countDown: function() {
      var date = new Date();

      var hour = date.getHours()
      if(hour > 12){
        this.nowTimeFirst = "PM"
        hour = (hour - 12)
      }else{
        this.nowTimeFirst = "AM"
      }
      var second= String(date.getSeconds()).padStart(2,"0")
      var min= String(date.getMinutes()).padStart(2,"0")
      this.nowTime = hour + ":" + min + ":" + second
    },
  },
  created(){
    var date = new Date();
    const days = [
      '일요일',
      '월요일',
      '화요일',
      '수요일',
      '목요일',
      '금요일',
      '토요일'
    ];
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();
    this.nowDate=year + "년" + month + "월" + day +"일" ;
    this.nowDayOfWeak = days[date.getDay()];
    var hour = date.getHours()
    if(hour > 12){
      this.nowTimeFirst = "PM"

      hour = (hour - 12);
    }else{
      this.nowTimeFirst = "AM"
    }
    var second= String(date.getSeconds()).padStart(2,"0")
    var min= String(date.getMinutes()).padStart(2,"0")
    this.nowTime = hour + ":" + min + ":" + second

    this.interval = setInterval(this.countDown, 1000);
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.gnb{
  padding: 15px;
  width: 100%;
  /*border-bottom: 3px solid #aaa;*/
  display: flex;
  align-items: flex-end;

}


.gnb .date{
  font-size: 22px;
  text-align: left;
  color: #777;
  width : 50%;
  margin-bottom: auto;
}


.gnb .time1{
  text-align: right;
  font-size: 25px;
  margin-right:30px;
  padding-top:20px;
}
.gnb .time2{
  width:50%;
  margin-right:30px;

  font-size: 55px;
  text-align: right;
  padding-bottom:20px;

}

</style>
